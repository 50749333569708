const inputEmailDeactive = $('.modal-input-email').find('input');
const labelEmailDeactive = $('.modal-input-email').find('label');
const modalDeactive = $('#modal-deactive-id');
const wrongEmail = $('#wrong-email');
const submitDeactive = $('#confirm-delete-button');
const modalContentForm = $('#ModalContentForm');
const modalContentInfo = $('#ModalContentInfo');
const deactiveForm = $('#DeactiveFormEcommerce');
const confirmDeactiveBtn = $('#confirmDeactive');
const wrapInput = deactiveForm.find('.js-form-wrap');
const closeAccConfirmIcon = $('.js-close-deactive-confirm');

inputEmailDeactive.focusin(function () {
    labelEmailDeactive.addClass('input-email-label-focus');
});
inputEmailDeactive.focusout(function () {
    if (this.value == '')
        labelEmailDeactive.removeClass('input-email-label-focus');
});

inputEmailDeactive.keyup(function () {
    if (!wrongEmail.hasClass('d-none'))
        wrongEmail.addClass('d-none');
});

$(document).on('keyup', function (e) {
    if (e.key == "Escape" && !modalDeactive.hasClass('cc-modalDialogClose')) {
        modalDeactive.addClass('cc-modalDialogClose')
        if (modalContentForm.hasClass('d-none'))
            modalContentForm.removeClass('d-none');
        if (!modalContentInfo.hasClass('d-none'))
            modalContentInfo.addClass('d-none');
    }
});


deactiveForm.on('submit', function (event) {
    event.preventDefault();
    $('body').spinner().start();
    var form = $(this);
    var data = form.serialize();
    data += '&' + submitDeactive.attr('name') + '=';
    if (data.indexOf('ajax') === -1)
        data += '&format=ajax';

    $.ajax({
        type: 'POST',
        url: form.attr('action'),
        data: data,
        success: function (response) {
            modalContentForm.addClass('d-none');
            modalContentInfo.removeClass('d-none');

            function handleClick() {
                clearTimeout(timeout);
                $('body').spinner().start();
                window.location.href = response.redirectUrl;
            }

            var timeout = setTimeout(function () {
                $('body').spinner().start();

                window.location.href = response.redirectUrl;
            }, 10000);
            confirmDeactiveBtn.on('click', function () {
                clearTimeout(timeout);
                $('body').spinner().start();
                window.location.href = response.redirectUrl;
            });

            confirmDeactiveBtn.on('click', handleClick);
            closeAccConfirmIcon.on('click', handleClick);
            $('body').spinner().stop();
        },
        error: function () {
            modalContentForm.removeClass('d-none');
            modalContentInfo.addClass('d-none');
            wrongEmail.removeClass('d-none');
            wrapInput.addClass('cc-form__wrap--error')
            $('body').spinner().stop();
        }
    }).fail(function () {
        modalContentForm.removeClass('d-none');
        modalContentInfo.addClass('d-none');
        wrongEmail.removeClass('d-none');
        $('body').spinner().stop();
    });
});

$('.js-close-button-deactive-id').on('click', function () {
    $('#ModalContentForm').removeClass('d-none');
    $('#ModalContentInfo').addClass('d-none');
    $('#modal-deactive-id').addClass('cc-modalDialogClose');
});

$('#delete-account-button-id').on('click', function () {
    console.log('test 2');

    $('#modal-deactive-id').removeClass('cc-modalDialogClose');
    $('div.modal-input-email').find('#dwfrm_profile_customer_email').val('')
});