'use strict';

const SELECTORS = {
  element: '.js-reading-bar',
  progress: '.js-reading-bar-progress',
  wrapper: '.js-reading-bar-area'
};

/**
 * Class to dynamically center an active element inside a container.
 */
class ReadingBar {
  constructor() {
    this.progressbarWrapper = $(SELECTORS.element);
    this.progressbar = $(SELECTORS.progress);
    this.area = $(SELECTORS.wrapper);
    this.init();
  }

  init() {
    if ((this.progressbarWrapper.length > 0) && (this.progressbar.length > 0) && (this.area.length > 0)) {
      $(window).on('scroll', () => {
        let scrollTop = $(window).scrollTop();
        let areaTop = $(SELECTORS.wrapper).offset().top;
        let areaHeight = $(SELECTORS.wrapper).innerHeight();
        let windowHeight = $(window).innerHeight();
        let barPercentage = (scrollTop - areaTop) / (areaHeight - windowHeight) * 100;

        $(SELECTORS.progress).css('width', `${barPercentage}%`);

        if (scrollTop > areaTop) {
          $(SELECTORS.element).addClass('cc-active');
        } else {
          $(SELECTORS.element).removeClass('cc-active');
        }
      });
    }
  }
}

module.exports = () => {
  new ReadingBar();
};
