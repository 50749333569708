'use strict';

const debounce = require('lodash.debounce');
const { alertMessage } = require('../common/common');

const SELECTORS = {
  element: '.js-open-nl-modal',
  field: '.js-nl-field',
  targetField: '.js-nf-target-field',
  newsletterModal: '#modalNewsletter',
  newsletterForm: '.js-newsletter-form',
  newsletterError: '.js-newsletter-error',
  newsletterErrorMessage: '.js-error-message',
  newsletterSuccess: '.js-newsletter-success',
  formControl: 'input.js-form-control, select.js-form-control',
  recaptchaContainer: '.js-newsletter-recaptcha',
  recaptchaBadge: '.grecaptcha-badge'
};

/**
 * Class to dynamically center an active element inside a container.
 */
class newsletterEmail {
  constructor() {
    this.trigger = $(SELECTORS.element);
    this.emailField = $(SELECTORS.field);
    this.emailTargetField = $(SELECTORS.targetField);
    this.init();
  }

  init() {
    if ((this.trigger.length > 0) && (this.emailField.length > 0) && (this.emailTargetField.length > 0)) {
      this.trigger.off('click').on('click', this.copyField.bind(this));
    }

    $(SELECTORS.newsletterForm).on('submit', (e) => {
      e.preventDefault();
      this.newsletterFormHandler();
    });

    /* Render recaptcha template */
    $(SELECTORS.newsletterModal).on('shown.bs.modal', function() {
      var $recaptchaContainer = $(SELECTORS.recaptchaContainer);
      var url = $recaptchaContainer.data('url');
      $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
          
          if(data && data.renderedTemplate){
            $recaptchaContainer.html(data.renderedTemplate);
          }

          $.spinner().stop();
        },
        error: function (err) {
          alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
          $.spinner().stop();
        }
      });

    });
  
    /* Remove recaptcha template */
    $(SELECTORS.newsletterModal).on('hidden.bs.modal', function() {
      $(SELECTORS.recaptchaBadge).remove();
      $(SELECTORS.recaptchaContainer).empty();
      $(SELECTORS.newsletterError).hide();
      $(SELECTORS.newsletterSuccess).hide();
      $(SELECTORS.newsletterForm).trigger('reset').show();
      const selectPickerElement = $(SELECTORS.newsletterForm).find('.js-form-select.selectpicker');
      selectPickerElement.find('option[value=""]').hide().parent().selectpicker('refresh');
    });
    
  }

  newsletterFormHandler() {
    $.spinner().start();
    var $form = $(SELECTORS.newsletterForm);
    let formData = $form.serialize();

    $(SELECTORS.newsletterError).hide();
    $(SELECTORS.newsletterSuccess).hide();

    $.ajax({
      url: $form.attr('action'),
      method: 'POST',
      data: formData,
      success: function (data) {

        if(data.error){
          $(SELECTORS.newsletterError).find(SELECTORS.newsletterErrorMessage).empty().text(data.errorMessage);
          $(SELECTORS.newsletterError).show();
        } else{
          $(SELECTORS.newsletterSuccess).show();
        }
        $(SELECTORS.newsletterForm).hide();
        $.spinner().stop();
      },
      error: function (err) {
        $(SELECTORS.newsletterForm).hide();
        $(SELECTORS.newsletterError).show();
        $.spinner().stop();
      }
    });
  }

  copyField() {
    $(SELECTORS.newsletterForm)[0].reset();
    let emailField = $(SELECTORS.field).val();
    if ((emailField !== '') && ($(SELECTORS.field).closest('.cc-form__wrap.cc-form__wrap--error').length === 0)) {
      $(SELECTORS.targetField).val(emailField);
    } else {
      $(SELECTORS.targetField).val('');
    }
  }
}

module.exports = () => {
  new newsletterEmail();
};
