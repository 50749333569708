'use strict';

var processInclude = require('base/util');

processInclude(require('./video'));
processInclude(require('./hero'));
processInclude(require('./hotspot'));
processInclude(require('./header'));
processInclude(require('./brands'));
processInclude(require('./menuCustomerCare'));
processInclude(require('./newsletterModal'));
processInclude(require('./productSlider'));
processInclude(require('./readingBar'));
